<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Название кассы</th>
                  <th v-for="currency in currencies" :key="currency.id">{{ currency.name }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(account) in bankAccounts" :key="account.id">
                  <td>
                    {{ account.id }}
                  </td>
                  <td>{{ account.name }}</td>
                  <th v-for="currency in currencies" :key="currency.id">
                    <span
                        v-if="account.balance[currency.id]"
                        :class="account.balance[currency.id].amount > 0 ? 'text-success' : 'text-danger'"
                    >
                      {{ account.balance[currency.id].amount !== 0 ? account.balance[currency.id].amount : '' }}
                    </span>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination,
} from 'bootstrap-vue';

export default {
  name: 'BankAccounts',
  components: {
    BCard, BButton, BPagination,
  },
  data() {
    return {
      bankAccounts: [],
      currencies: [],
    };
  },
  async mounted() {
    await this.getCurrencies();
    await this.getBankAccounts();
  },
  methods: {
    async getBankAccounts() {
      this.bankAccounts = (await this.$api.bankAccounts.list()).data;
    },
    async getCurrencies() {
      this.currencies = (await this.$api.currencies.list()).data;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
